@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 84% 4.9%;

        --card: 0 0% 100%;
        --card-foreground: 222.2 84% 4.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 222.2 84% 4.9%;

        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;

        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;

        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;

        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;

        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 210 40% 98%;

        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;
        --ring: 222.2 84% 4.9%;

        --radius: 0.5rem;
    }

    .dark {
        --background: 222.2 84% 4.9%;
        --foreground: 210 40% 98%;

        --card: 222.2 84% 4.9%;
        --card-foreground: 210 40% 98%;

        --popover: 222.2 84% 4.9%;
        --popover-foreground: 210 40% 98%;

        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 11.2%;

        --secondary: 217.2 32.6% 17.5%;
        --secondary-foreground: 210 40% 98%;

        --muted: 217.2 32.6% 17.5%;
        --muted-foreground: 215 20.2% 65.1%;

        --accent: 217.2 32.6% 17.5%;
        --accent-foreground: 210 40% 98%;

        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 210 40% 98%;

        --border: 217.2 32.6% 17.5%;
        --input: 217.2 32.6% 17.5%;
        --ring: 212.7 26.8% 83.9%;
    }
}

@layer base {
    * {
        @apply border-border;
    }

    body {
        @apply bg-background text-foreground;
    }
}

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Apply a fixed gradient background to the body */
body {
    background: linear-gradient(to bottom, #5b21b6, #000); /* Your gradient */
    background-attachment: fixed; /* Keeps the background fixed */
    background-size: cover; /* Ensures the background covers the entire area */
    background-repeat: no-repeat; /* Prevents the background from repeating */

}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3); /* Light grey background */
    border-top: 4px solid white; /* White spinner color */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

/* Add the spinning animation */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Custom scrollbar styles */
.overflow-y-auto::-webkit-scrollbar {
    width: 9px; /* Width of the scrollbar */
}

.overflow-y-auto::-webkit-scrollbar-thumb {
    background-color: gray; /* Color of the scrollbar thumb */
    border-radius: 6px; /* Roundness of the scrollbar thumb */
    border: 3px solid rgba(255, 255, 255, 0.3); /* Padding around the thumb with transparency */
}

.overflow-y-auto::-webkit-scrollbar-track {
    background: transparent; /* Background of the scrollbar track */
}

/* Styling for episode cards to match the design */
.episode-card {
    display: flex;
    flex-direction: row; /* Arrange image and text side by side */
    align-items: center; /* Vertically center the content */
    justify-content: flex-start; /* Align content to the start */
    height: 160px; /* Fixed height for the cards */
    overflow: hidden; /* Hide overflow content */
    padding: 16px;
    border-radius: 12px; /* Rounded corners for the card */
    background-color: #1f1f1f; /* Dark background for better contrast */
}

.episode-card img {
    width: 120px; /* Fixed width for the image */
    height: 100%; /* Full height of the card */
    object-fit: cover;
    border-radius: 8px; /* Rounded corners for the image */
    margin-right: 16px; /* Space between image and text */
}

.episode-card h3 {
    font-size: 1rem;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* Ensure title is in a single line with ellipsis */
}

.episode-card .description {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    white-space: normal; /* Allows text to break to new lines */
    height: 60px; /* Fixed height to match line clamping */
    color: #cccccc; /* Slightly lighter text color for contrast */
}


